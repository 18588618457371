import './index.scss'

import React, { useEffect, useRef } from 'react'
import mapboxgl, { LngLatBounds } from 'mapbox-gl'
import API from '../../API'
import Lang from '../../Lang'
import Map from '../../Components/Map'
import Page from '../../Components/Page'
import { DateTime } from 'luxon'
import DeviceDetails from '../Devices/Components/DeviceDetails'
import Placeholder from '../../Components/Placeholder'
import { useState } from 'react'

export default function FaultsPage() {
	const [selectedDevice, setSelectedDevice] = useState(null)
	const [faults, setFaults] = useState(null)

	const mapRef = useRef(null)

	useEffect(() => {
		mapRef.current.resize()

		if (selectedDevice) {
			const curZoom = mapRef.current.getZoom()
			const targetZoom = 15

			const flyToOptions = {
				center: [selectedDevice.longitude, selectedDevice.latitude],
				...(curZoom < targetZoom && { zoom: targetZoom }),
				essential: true
			}
			mapRef.current.flyTo(flyToOptions)
		}
	}, [selectedDevice])

	function onDeviceSelect(device) {
		selectDevice(device)
	}

	function selectDevice(device) {
		setSelectedDevice((curDevice) => {
			if (device === curDevice) return curDevice

			if (curDevice) {
				curDevice.marker?.getElement().classList.remove('selected')
			}

			if (device) {
				device.marker?.getElement().classList.add('selected')
			}

			return device
		})
	}

	function zoomDevices(devices) {
		if (devices.length === 0) return

		const zoomBounds = new LngLatBounds()
		for (let d of devices) {
			if (!d.latitude || !d.longitude) continue

			zoomBounds.extend([d.longitude, d.latitude])
		}

		mapRef.current.fitBounds(zoomBounds, { padding: 90, duration: 250 })
	}

	function createMarkerElementForDevice(dev) {
		const markerElement = document.createElement('div')
		markerElement.className = 'Marker DeviceMarker'

		const iconElement = document.createElement('i')
		iconElement.className = 'fa-fw fa-duotone fa-triangle-exclamation'
		markerElement.appendChild(iconElement)

		const labelElement = document.createElement('div')
		labelElement.className = 'label'
		labelElement.innerText = dev.id
		markerElement.appendChild(labelElement)

		return markerElement
	}

	async function hydrate() {
		const faults = await API.call('Faults.Get')
		setFaults(faults)

		const devices = []
		for (let f of faults) {
			const d = f.device

			if (!d.latitude || !d.longitude) continue
			if (f.ended) continue

			devices.push(d)

			const markerElement = createMarkerElementForDevice(d)
			const marker = new mapboxgl.Marker({ element: markerElement }).setLngLat([d.longitude, d.latitude]).addTo(mapRef.current)

			markerElement.addEventListener('click', (e) => onDeviceSelect(d))
			d.marker = marker
		}

		zoomDevices(devices)
	}

	return (
		<Page title={Lang.get('Faults')} icon="fa-duotone fa-triangle-exclamation" className="FaultsPage" noPadding>
			<Placeholder overlay overlayVisible={faults == null} loading label={Lang.get('Loading faults') + ' ...'} />

			{faults && (
				<>
					{faults && (
						<div className="SideContainer">
							{faults.length === 0
								? Lang.get('There are no faults at the moment.')
								: (() => {
										const currentFaults = faults.filter((fault) => fault.ended === null)
										const previousFaults = faults.filter((fault) => fault.ended !== null)
										return (
											<>
												<FaultsList title={Lang.get('Ongoing Faults')} faults={currentFaults} onDeviceSelect={onDeviceSelect} />
												<FaultsList
													title={Lang.get('Resolved Faults')}
													faults={previousFaults}
													onDeviceSelect={onDeviceSelect}
													defaultCollapsed
												/>
											</>
										)
								  })()}
						</div>
					)}
					{selectedDevice && (
						<DeviceDetails
							device={selectedDevice}
							view={{
								selectDevice,
								zoomDevices
							}}
						/>
					)}
				</>
			)}

			<div className="MapContainer">
				<Map
					onReady={(ref) => {
						mapRef.current = ref.current
						hydrate()
					}}
				/>
			</div>
		</Page>
	)
}

function FaultsList(props) {
	if (props.faults.length == 0) {
		return null
	}
	const [collapsed, setCollapsed] = React.useState(props.defaultCollapsed)
	let faultsToShow = props.faults
	if (collapsed) {
		faultsToShow = []
	}
	return (
		<div className="FaultsList">
			<div className="header" onClick={() => setCollapsed(!collapsed)}>
				<i className={'fa-fw expander fa-duotone ' + (collapsed ? 'fa-chevron-right' : 'fa-chevron-down')} />
				<span>
					{props.title} ({props.faults.length})
				</span>
			</div>
			{faultsToShow.map((fault, i) => {
				const t = DateTime.fromISO(fault.started)
				let duration = null
				if (fault.ended) {
					duration = DateTime.fromISO(fault.ended).diff(t)
				} else {
					duration = DateTime.now().diff(t)
				}

				// 14 minutes or 3 hours or 10 days
				if (duration.as('minutes') < 60) {
					duration = duration.toFormat('mm') + ' ' + Lang.get('minutes')
				} else if (duration.as('hours') < 24) {
					duration = duration.toFormat('hh') + ' ' + Lang.get('hours')
				} else {
					duration = duration.toFormat('dd') + ' ' + Lang.get('days')
				}
				if (duration[0] === '0') {
					duration = duration.slice(1)
				}

				return (
					<div
						className="Fault"
						key={`fault-${i}${fault.started}`}
						onClick={() => props.onDeviceSelect(fault.device, { from: 'list' })}>
						<div className="icon">
							<i className="fa-duotone fa-triangle-exclamation" />
						</div>
						<div className="details">
							<div className="deviceId">
								{fault.device.id} - <span className="type">{Lang.get('Unreacheable')}</span>
							</div>

							<div className="from">
								<strong>{Lang.get('From')}:</strong>
								{t.toFormat('dd.LL.yy HH:mm')}
							</div>
							{fault.ended && (
								<div className="to">
									<strong>{Lang.get('To')}:</strong>
									{DateTime.fromISO(fault.ended).toFormat('dd.LL.yy HH:mm')}
								</div>
							)}
							<div className="duration">
								<strong>{Lang.get('Duration')}:</strong>
								{duration}
							</div>
						</div>
					</div>
				)
			})}
		</div>
	)
}
